import React from "react"
import { Grid, Col, Row } from "react-flexbox-grid"

interface WrapperProps {
  children: React.ReactNode
  className?: string
}

const Wrapper: React.FC<WrapperProps> = (props) => {
  return (
    <Grid fluid id="wrapper">
      <Row>
        <Col xs={12} md={10} mdOffset={1} className={props.className}>
          {props.children}
        </Col>
      </Row>
    </Grid>
  )
}

export default Wrapper
