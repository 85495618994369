import { Song } from "../types/song"

// Fisher-Yates shuffle stolen from stackoverflow
export function shuffle(arr: Array<Song>, size: number) {
  var shuffled = arr.slice(0),
    i = arr.length,
    temp: Song,
    index: number
  while (i--) {
    index = Math.floor((i + 1) * Math.random())
    temp = shuffled[index]
    shuffled[index] = shuffled[i]
    shuffled[i] = temp
  }
  return shuffled.slice(0, size)
}
