import styled from "styled-components"
import { globalTransition } from "./Style"

export const Button = styled.button`
  background-color: var(--primary);
  transition:
    color ${globalTransition},
    background-color ${globalTransition};
  color: var(--fg);
  border: 0;
  border-radius: 5px;
  font-weight: 700;
  padding: 0.5em;
  margin-bottom: 0.5em;
  width: 100%;
  height: auto;
  text-align: center;
  cursor: pointer;
  @media (max-width: 375px) {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  @media (min-width: 376px) {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  padding: 0.4375em 1em 0em 1em;
`

export const NoteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  transition:
    color ${globalTransition},
    background-color ${globalTransition};
  color: var(--fg);
  border: 0;
  border-radius: 5px;
  font-weight: 700;
  margin-bottom: 0.5em;
  width: 100%;
  height: 5em;
  text-align: center;
  cursor: pointer;
  @media (max-width: 375px) {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  @media (min-width: 376px) {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  padding: 0.4375em 1em 0.4375em 1em;
`

export const NoteSvgWrapper = styled.div`
  height: auto;
`

export const NoteSvgStyle = styled.img`
  height: 100%
  max-width: none;
  max-height: 4em;
  filter: var(--fgFilter);
  margin-bottom: 0;
`
