import { createGlobalStyle } from "styled-components"
import chroma from "chroma-js"
import { DefaultTheme } from "styled-components"
import { SnapsenColorConf, SnapsenTagLabelMap } from "../types/snapsenConfig"
import slugify from "underscore.string/slugify"

export const type = {
  baseFontSize: "16px",
  baseLineHeight: 1.4,
  headerFontFamily: ["Raleway", "serif"],
  bodyFontFamily: ["Raleway", "serif"],
  scaleRatio: 2,
  googleFonts: [
    {
      name: "Raleway",
      styles: ["400", "400i", "500", "500i", "700", "700i"],
    },
  ],
}

const generateTypeColors = (
  typeLabels: SnapsenTagLabelMap[],
  darkMode: boolean
) => {
  const key = darkMode ? "darkColor" : "color"
  const typeColors = typeLabels.map((typeLabel) => {
    return `--${slugify(typeLabel.value)}: ${typeLabel[key]};`
  })
  return `
  ${typeColors.join("\n")}
  `
}

const generateColors = (
  colors: SnapsenColorConf,
  filter: string,
  darkMode: boolean
) => {
  const coefficient = colors.shadeFactor ? colors.shadeFactor : 1
  // TODO: Fix 'any' types
  // Make chroma color more intense, depending on color mode
  function shade(color: any, factor: number) {
    return darkMode
      ? color.brighten(factor * coefficient).hex()
      : color.darken(factor * coefficient).hex()
  }

  // Make chroma color less intense, depending on color mode
  function fade(color: any, factor: number) {
    return darkMode
      ? color.darken(factor * coefficient).hex()
      : color.brighten(factor * coefficient).hex()
  }

  return `
    --bg: ${colors.bg};
    --fg: ${colors.fg};
    --bgRGB: ${chroma(colors.bg).rgb()};
    --light: ${darkMode ? colors.fg : colors.bg};
    --dark: ${darkMode ? colors.bg : colors.fg};
    --textLink: ${shade(chroma(colors.accent1).saturate(1), 0.1)};
    --fgFilter: ${filter};
    --primary: ${colors.primary}; // border of focused input
    --primary75: ${fade(chroma(colors.primary), 0.05)};
    --primary50: ${fade(chroma(colors.primary), 0.1)};
    --primary25: ${fade(chroma(colors.primary), 0.2)}; // bg of hovered option
    --danger: ${colors.alert};
    --dangerLight: ${fade(chroma(colors.alert), 2.0)};
    --neutral0: ${colors.bg};
    --neutral5: ${shade(chroma(colors.bg), 0.05)};
    --neutral10: ${shade(chroma(colors.neutral), 0.1)};
    --neutral20: ${shade(chroma(colors.neutral), 0.2)};
    --neutral30: ${shade(chroma(colors.neutral), 0.3)};
    --neutral40: ${shade(chroma(colors.neutral), 0.4)};
    --neutral50: ${shade(chroma(colors.neutral), 0.5)};
    --neutral60: ${shade(chroma(colors.neutral), 0.6)};
    --neutral70: ${shade(chroma(colors.neutral), 0.7)};
    --neutral80: ${shade(chroma(colors.neutral), 0.8)};
    --neutral90: ${shade(chroma(colors.neutral), 0.9)};
  `
}

export const globalTransition = "200ms linear"
export const GlobalStyle = createGlobalStyle<DefaultTheme>`
  body {
    ${(props) =>
      generateColors(props.colorsLight, props.colorsLightFilters.fg, false)}
    ${(props) => generateTypeColors(props.typeLabels, false)}
    background-color: var(--dark);
    color: var(--fg);
    transition: color ${globalTransition}, background-color ${globalTransition};
  }

  body.dark {
    -webkit-font-smoothing: antialiased;
    ${(props) =>
      generateColors(props.colorsDark, props.colorsDarkFilters.fg, true)}
    ${(props) => generateTypeColors(props.typeLabels, true)}
  }

  a {
    text-decoration: none;
    color: var(--textLink);
    transition: color ${globalTransition}, background-color ${globalTransition};
  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .container-fluid > div:first-child {
    margin-left: 0;
    margin-right: 0;
  }

  .container-fluid > div:first-child > div:first-child {
    padding-left: 0;
    padding-right: 0;
  }

  .hidden {
    display: none;
  }

  .screenreader-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .annotation {
    font-style: italic;
    float: left;
    display: inline;
    padding-right: 0.5em;
  }
  .slick-current {
    font-weight: 700;
  }
  .slick-slider {
    margin: 0 2em;
  }
  .slick-prev {
    color: var(--primary) !important;
    :before {
      color: var(--primary) !important;
    }
  }
  .slick-next {
    color: var(--primary) !important;
    :before {
      color: var(--primary) !important;
    }
  }
  .slick-dots li button:before {
    color: var(--neutral40) !important;
  }

  hr {
    background-color: var(--fg);
    transition: color ${globalTransition}, background-color ${globalTransition};
  }

  .Collapsible__trigger {
    display: block;
    position: relative;
    margin-bottom: 0.5em;
    padding-left: 1rem;
    border-top: 1px solid var(--fg);
    text-transform: capitalize;
    font-weight: 700;
    font-size: 1.2rem;
    transition: border-top ${globalTransition};

    &:after {
      content: 'V';
      font-weight: bold;
      position: absolute;
      right: 10px;
      bottom: 0px;
      display: block;
      transition: transform 300ms;
    }

    &.is-open {
      &:after {
        transform: rotateZ(180deg);
      }
    }
  }

  .slick-prev:before, .slick-next:before {
    color: var(--fg) !important;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

`

export default { GlobalStyle, globalTransition, type }
