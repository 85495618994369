import styled from "styled-components"
export const buttonProps = {
  color: "var(--fg)",
  size: "1.5em",
  strokeWidth: 2,
}

export const playerButtonProps = {
  ...buttonProps,
  "padding-top": "3px",
}

export const ButtonIcon = styled.span`
  vertical-align: middle;
  padding: 0.25em;
`
