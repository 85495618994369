import React from "react"
import Layout from "./src/components/Layout/Layout"
import { show } from "./src/components/Layout/UpdateNotification"
import { SearchProvider } from "./src/components/Search/SearchContext"
import type { GatsbyBrowser } from "gatsby"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Wrap all pages with the Layout component - persistent across navigations
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  // https://andremonteiro.pt/gatsby-i18next-wrap-page-element/
  //return <Layout path={props.path}>{element}</Layout>
  return React.cloneElement(
    element, // I18nextProvider
    element.props,
    element.props.children &&
      React.cloneElement(
        element.props.children, // I18nextContext.Provider
        element.props.children?.props,
        React.createElement(
          Layout,
          props,
          element.props.children?.props.children
        )
      )
  )
}

// Providers should apparently be here
export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => <SearchProvider>{element}</SearchProvider>

export const onServiceWorkerUpdateFound = show
