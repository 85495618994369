import React, { useState, useEffect } from "react"
import { Col, Row } from "react-flexbox-grid"
import styled from "styled-components"
import { Button } from "../../styles/Button"

let updateWhenNotRendered = false
let setHasUpdate2: (hasUpdate: boolean) => void = null

export const show = () => {
  if (setHasUpdate2) {
    setHasUpdate2(true)
  } else {
    updateWhenNotRendered = true
  }
}

const Wrapper = styled.div`
  margin-top: 1em;
`

// There can be only one
const UpdateNotification: React.FunctionComponent = () => {
  const [hasUpdate, setHasUpdate] = useState<boolean>(false)

  useEffect(() => {
    if (setHasUpdate2) {
      console.error(
        "Multiple instances of UpdateNotification are rendered. This is not supported."
      )
    }
    setHasUpdate2 = setHasUpdate
    setHasUpdate(updateWhenNotRendered)
    return () => {
      updateWhenNotRendered = hasUpdate
      setHasUpdate2 = null
    }
  }, [])

  return (
    <Wrapper style={{ display: hasUpdate ? "block" : "none" }}>
      <Row middle="xs">
        <Col sm={12} md={6}>
          <>
            <h3>Update available!</h3>
            <p>
              Press the <i>Update</i> button to download the newest version of
              this page. Press <i>Cancel</i> to keep using the cached version.
            </p>
          </>
        </Col>
        <Col sm={6} md={3}>
          <Button
            // @ts-ignore
            onClick={() => window.location.reload(true)}
          >
            Update
          </Button>
        </Col>
        <Col sm={6} md={3}>
          <Button onClick={() => setHasUpdate(false)}>Cancel</Button>
        </Col>
      </Row>
    </Wrapper>
  )
}

export default UpdateNotification
