import React, {
  KeyboardEventHandler,
  useState,
  useEffect,
  MouseEvent,
  TouchEvent,
  useContext,
} from "react"
import { graphql, useStaticQuery } from "gatsby"
import Select, {
  components,
  ActionMeta,
  InputActionMeta,
  DropdownIndicatorProps,
  ClearIndicatorProps,
  GroupBase,
  ControlProps,
  IndicatorsContainerProps,
  IndicatorSeparatorProps,
} from "react-select"

import { customStyles } from "../../styles/Searchbar"
import { uniqueSongTags } from "../../utils/uniqueSongTags"
import { SongTag, SongTagLabelMap } from "../../types/song"
import { Filter, X } from "react-feather"
import { useSearch } from "./SearchContext"
import { searchbarColors } from "../../styles/Searchbar"
import { useI18next, I18nextContext } from "gatsby-plugin-react-i18next"

interface CustomDropdownIndicatorProps
  extends DropdownIndicatorProps<SongTag, true> {
  toggleMenu: () => void
}

//  TODO: Figure out what type to use for clearButtonStyle. Using CSSProperties
//  leads to many errors of the type: Type 'AlignTracks | AlignTracks[]' is not
//  assignable to type 'AlignTracks'.
interface CustomClearIndicatorProps extends ClearIndicatorProps<SongTag, true> {
  isSearchEmpty: boolean
  onClearButtonClick: () => void
  clearButtonStyle: any //CSSProperties
  accentColor?: any
}

const CustomDropdownIndicator = (props: CustomDropdownIndicatorProps) => {
  const newProps = {
    id: "filter-button",
    "aria-hidden": props.innerProps["aria-hidden"],
    onMouseDown: (event: MouseEvent<HTMLDivElement>) => {
      props.toggleMenu()
      event.preventDefault()
    },
    onTouchEnd: (event: TouchEvent<HTMLDivElement>) => {
      props.toggleMenu()
      event.preventDefault()
      event.stopPropagation()
    },
  }
  return (
    <components.DropdownIndicator {...props} innerProps={newProps}>
      <Filter size={"1.125em"} />
    </components.DropdownIndicator>
  )
}

const IndicatorsContainer = (
  props: IndicatorsContainerProps<SongTag, true>
) => {
  return (
    <div>
      <components.IndicatorsContainer {...props} />
    </div>
  )
}

const IndicatorSeparator = (props: IndicatorSeparatorProps<SongTag, true>) => {
  return <components.IndicatorSeparator {...props} />
}

const ClearButton = (props: CustomClearIndicatorProps) => {
  if (props.isSearchEmpty) {
    return null
  } else {
    return (
      <div
        id={"clear-button"}
        onMouseDown={props.onClearButtonClick}
        onTouchEnd={props.onClearButtonClick}
        style={props.clearButtonStyle}
      >
        <X size={"1.125em"} />
      </div>
    )
  }
}

const Control = ({ children, ...props }: ControlProps<SongTag, true>) => {
  // @ts-ignore
  const { onClearButtonClick, isSearchEmpty, toggleMenu } = props.selectProps
  const dropdownButtonProps = { ...props, toggleMenu }
  const { label, ...clearButtonStyle } = props.getStyles(
    "dropdownIndicator",
    props
  )
  const clearButtonProps = {
    ...props,
    isSearchEmpty,
    onClearButtonClick,
    clearButtonStyle,
  }

  return (
    <components.Control {...props}>
      {children}
      <IndicatorsContainer {...props}>
        <ClearButton {...clearButtonProps} />
        <IndicatorSeparator {...props} />
        <CustomDropdownIndicator {...dropdownButtonProps} />
      </IndicatorsContainer>
    </components.Control>
  )
}

interface SearchFieldProps {
  className?: string
}

export const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}

function groupBadgeStyles(color: string | undefined) {
  return {
    backgroundColor: color ? color : "var(--bg)",
    borderRadius: "2em",
    color: "var(--bg)",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center" as const,
  }
}

export const SearchFieldComponent = ({ className }: SearchFieldProps) => {
  const { query, tags, setQuery, setTags, setFocused } = useSearch()
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
  const [allTags, setAllTags] = useState<GroupBase<SongTag>[]>(null)
  const { t, navigate: i18nextNavigate } = useI18next()
  const originalPath = useContext(I18nextContext).originalPath

  const { configQuery, tagQuery } = useStaticQuery(graphql`
    query {
      configQuery: site {
        siteMetadata {
          tags {
            label
            value
            color
            labelMap {
              value
              label
              shortLabel
            }
          }
        }
      }
      tagQuery: allMarkdownRemark {
        nodes {
          tag {
            tag
            value
            label
            shortLabel
            color
          }
        }
      }
    }
  `)

  useEffect(() => {
    const tags = uniqueSongTags(tagQuery.nodes, availableSearchTerms)
    setAllTags(tags)
  }, [tagQuery])

  const availableSearchTerms: SongTagLabelMap[] = configQuery.siteMetadata.tags

  const formatGroupLabel = (data: GroupBase<SongTag>) => {
    const groupColor: string | undefined =
      data?.options && Array.isArray(data.options)
        ? data.options.find((opt: SongTag) => opt?.hasOwnProperty("color"))
            .color
        : "var(--bg)"
    const termMap: any | undefined = availableSearchTerms.find(
      (termMap) => termMap.value === data.label
    )
    const newGroupLabel: string = !termMap
      ? data.label
      : termMap.label
        ? termMap.label
        : data.label

    return (
      <div style={groupStyles}>
        <span>{newGroupLabel}</span>
        <span style={groupBadgeStyles(groupColor)}>{data.options.length}</span>
      </div>
    )
  }

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    switch (event.key) {
      case "ArrowDown":
        if (!menuIsOpen) {
          toggleMenu()
          event.preventDefault()
        }
        break
      case "Escape":
        if (menuIsOpen) {
          toggleMenu()
        }
        break
    }
  }

  const activateSearch = () => {
    // If we're on song details page when search field activates, navigate to root
    if (!(originalPath === "/")) {
      i18nextNavigate("/")
    }

    setFocused(true)

    // HACK: wtf iOS Safari
    // https://blog.opendigerati.com/the-eccentric-ways-of-ios-safari-with-the-keyboard-b5aa3f34228d
    //
    // Scroll to top of page when activating search if device is iOS, else the
    // user will see a blank page if they have scrolled the page before
    // activating search if (iOS) {
    //window.scrollTo({ top: 0 })
    //}
  }

  const clearSearch = () => {
    setQuery("")
    setTags([])
  }

  const closeSearch = () => {
    setFocused(false)
  }

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  // Runs when selected options change
  const handleChange = (
    selectedOptions: readonly SongTag[],
    actionType: ActionMeta<SongTag>
  ) => {
    if (
      (selectedOptions.length === 0 && query === "") ||
      actionType.action === "clear"
    ) {
      clearSearch()
    } else {
      setTags(selectedOptions)
    }
  }

  // Runs when live input changes
  const handleInputChange = (
    newInputValue: string,
    { action, prevInputValue }: InputActionMeta
  ) => {
    // If we're on song details page and search field changes, navigate to root
    if (!(originalPath === "/")) {
      i18nextNavigate("/")
    }

    switch (action) {
      case "input-change":
        setQuery(newInputValue)
        return newInputValue

      // Do not clear the input field on menu-close/input-blur
      default:
        return newInputValue ? prevInputValue : ""
    }
  }

  // Exits search mode if search field is empty when it's being unfocused
  const handleBlur = () => {
    if (tags.length === 0 && !query) {
      closeSearch()
    }
    if (menuIsOpen) {
      toggleMenu()
    }
  }

  return (
    <div className={className}>
      <Select<SongTag, true>
        id="song-search"
        instanceId="song-search"
        name="song-search"
        components={{
          Control: Control,
          DropdownIndicator: null,
          ClearIndicator: null,
        }}
        // @ts-ignore
        onClearButtonClick={clearSearch}
        isSearchEmpty={tags.length === 0 && !query}
        toggleMenu={toggleMenu}
        options={allTags}
        formatGroupLabel={formatGroupLabel}
        onFocus={activateSearch}
        onBlur={handleBlur}
        onChange={handleChange}
        value={tags}
        onInputChange={handleInputChange}
        inputValue={query}
        onKeyDown={handleKeyDown}
        menuIsOpen={menuIsOpen}
        isMulti
        placeholder={t("Search") + "..."}
        noOptionsMessage={() => t("No tags")}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          colors: { ...searchbarColors },
        })}
      />
    </div>
  )
}
