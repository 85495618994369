import { GroupBase } from "react-select"
import { Song, SongTagLabelMap, SongTag } from "../types/song"
import { Map, Set } from "immutable"

export function uniqueSongTags(
  songs: Song[],
  tagMaps: SongTagLabelMap[]
): GroupBase<SongTag>[] {
  return tagMaps.map((tagMap) => {
    const tagArray = songs
      .flatMap((song) => song.tag)
      .filter((tag) => tag.tag === tagMap.value)
      .map((tag) => {
        const map = Map(tag)
        return map.set("label", map.get("value")).delete("shortLabel")
      })
    let tagSet = Set(tagArray)
    const collectedTags = tagSet.toJS() as SongTag[]
    return {
      label: tagMap.label,
      options: collectedTags,
    }
  })
}
