import React, { createContext, useContext, useState, useEffect } from "react"
import { Song, IndexSong, SongTag } from "../../types/song"
import { graphql, useStaticQuery } from "gatsby"
import { Document } from "flexsearch"
import { shuffle } from "../../utils/shuffle"

interface SearchContextData {
  query: string
  setQuery: (query: string) => void
  tags: readonly SongTag[]
  setTags: (tags: readonly SongTag[]) => void
  focused: boolean
  setFocused: (focused: boolean) => void
  searching: boolean
  index: Document<IndexSong>
  store: { [k: string]: Song }
  fields: string[]
  defaultSongs: Song[]
}

const defaultData = {
  query: "",
  setQuery: () => {},
  tags: [],
  setTags: () => {},
  focused: false,
  setFocused: () => {},
  searching: false,
  index: null,
  store: null,
  fields: [],
  defaultSongs: [],
}

const Context = createContext<SearchContextData>(defaultData)

type SearchProviderProps = {
  children: React.ReactNode
}

export const SearchProvider: React.FC<SearchProviderProps> = (props) => {
  const { allSongQuery, indexQuery } = useStaticQuery(graphql`
    query {
      indexQuery: songSearch {
        index
        options
      }
      allSongQuery: allMarkdownRemark {
        nodes {
          id
          title
          contentFull
          slug
          tag {
            tag
            value
            label
            shortLabel
            color
          }
          book {
            slug
            title
          }
          html
          frontmatter {
            type
            pre
            page
            melody
            melodyCredit
            lyricsCredit
            songOrder
            startnote {
              note
              clefLine
              clefOctaveChange
              clefSign
            }
          }
          startnote {
            name
            svgFile {
              publicURL
            }
            noteStep
            noteOctave
            noteAlter
            clefLine
            clefOctaveChange
            clefSign
          }
        }
      }
    }
  `)

  const [index, setIndex] = useState<Document<IndexSong> | null>(null)
  const [store, setStore] = useState<{ [k: string]: Song } | null>(null)
  const [fields, setFields] = useState<string[]>([])
  const [query, setQuery] = useState<string>("")
  const [tags, setTags] = useState<readonly SongTag[]>([])
  const [focused, setFocused] = useState<boolean>(false)
  const [defaultSongs, setDefaultSongs] = useState<Song[]>([])

  // Index (& fields)
  useEffect(() => {
    const importedIndex = JSON.parse(indexQuery.index)
    const indexOpts = JSON.parse(indexQuery.options)
    const newIndex = new Document<IndexSong>(indexOpts)
    for (const key in importedIndex) {
      newIndex.import(key, importedIndex[key])
    }
    setIndex(newIndex)
    setFields(indexOpts.document.index.map((v) => v.field))
  }, [indexQuery])

  // Store and default songs (shown when query is empty)
  useEffect(() => {
    setStore(
      Object.fromEntries(
        allSongQuery.nodes.map((song: Song) => [song.id, song])
      )
    )
    setDefaultSongs(shuffle(allSongQuery.nodes, allSongQuery.nodes.length))
  }, [allSongQuery])

  const searching = index && store && (query !== "" || tags.length > 0)

  return (
    <Context.Provider
      value={{
        query,
        setQuery,
        tags,
        setTags,
        focused,
        setFocused,
        searching,
        index,
        store,
        fields,
        defaultSongs,
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

export const useSearch = () => useContext(Context)
