import React from "react"

if (typeof Window !== "undefined") {
  // Will be cloned into each element
  const style = document.createElement("style")
  style.textContent = `
    :host {
      --svg-color: var(--fg);
      display: inline-flex;
      width: min-content;
    }
    svg,div {
      flex: 1;
      align-self: stretch;
      min-width: 0;
      min-height: 0;
    }
    svg {
      stroke: var(--svg-color);
      fill: var(--svg-color);
    }
  `

  // Used to parse fetched SVG
  const parser = new DOMParser()

  const isString = (str: any) =>
    typeof str === "string" || str instanceof String

  // Cache each SVG response
  // TODO: How to get the browser to handle the caching instead? The response headers for the SVG requests would need to include 'Cache-Control'.
  const iconElementCache: Record<string, Promise<HTMLElement>> = {}

  // Custom element that themes an SVG based on var(--bg) color.
  class ThemedSVGElement extends HTMLElement {
    _src?: string
    _alt?: string

    static get observedAttributes() {
      return ["src", "alt"]
    }

    constructor() {
      super()
      this.attachShadow({ mode: "open" })
      this.shadowRoot.appendChild(style.cloneNode(true))
    }

    get src() {
      return this._src
    }

    set src(val: string) {
      this._src = val
      if (isString(val)) {
        this.setAttribute("src", val)
        this._fetchSrc()
      } else {
        this.removeAttribute("src")
        this._showAlt()
      }
    }

    get alt() {
      return this._alt
    }

    set alt(val: string) {
      this._alt = val
      if (isString(val)) {
        this.setAttribute("alt", val)
        this.setAttribute("aria-label", val)
      } else {
        this.removeAttribute("alt")
        this.removeAttribute("aria-label")
      }
      this._showAlt()
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
      // Stop infinite recursion
      if (newValue === oldValue) return
      switch (name) {
        case "src":
          this.src = newValue
          break
        case "alt":
          this.alt = newValue
          break
      }
    }

    connectedCallback() {
      // Not allowed to be done in constructor
      this.setAttribute("role", "img")
    }

    _hasContent() {
      return this.shadowRoot.lastElementChild.tagName !== "STYLE"
    }

    _replaceOld(newEl?: Element) {
      if (this._hasContent()) {
        this.shadowRoot.replaceChild(newEl, this.shadowRoot.lastElementChild)
      } else {
        this.shadowRoot.appendChild(newEl)
      }
    }

    _showAlt() {
      if (isString(this._alt)) {
        const div = document.createElement("div")
        div.textContent = this._alt
        this._replaceOld(div)
      } else if (this._hasContent()) {
        this.shadowRoot.removeChild(this.shadowRoot.lastElementChild)
      }
    }

    async _fetchSrc() {
      let promise = iconElementCache[this._src]

      // Fetch only if not cached
      if (!promise) {
        promise = fetch(this._src)
          .then(res => res.text())
          .then(text => document.adoptNode(parser.parseFromString(text, "image/svg+xml").documentElement))
          .catch(() => undefined)
          iconElementCache[this._src] = promise
      }

      const svgCached = await promise
      if (svgCached) {
        // Need to clone the cached element before using it
        const svg = svgCached.cloneNode(true) as Element
        svg.setAttribute("part", "svg")
        this._replaceOld(svg)
      } else {
        this._showAlt()
      }
    }
  }

  customElements.define("sn-themedsvg", ThemedSVGElement)
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ["sn-themedsvg"]: {
        src: string
        alt: string
        class: string
        id: string
      }
    }
  }
}

interface ThemedSVGProps {
  className?: string
  src?: string
  alt?: string
  id?: string
}

export const ThemedSVG: React.FC<ThemedSVGProps> = ({
  className,
  src,
  alt,
  id,
}) => (
  <sn-themedsvg class={className} src={src} alt={alt} id={id}></sn-themedsvg>
)
