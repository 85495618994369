import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { SearchFieldComponent } from "../Search/SearchField"
import { ArrowLeft, Moon, Sun } from "react-feather"
import { Link } from "gatsby"
// @ts-ignore
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import { globalTransition } from "../../styles/Style"
import { ThemedSVG } from "../Media/ThemedSVG"
import iconPath from "../../images/glass-icon.svg"
import { buttonProps } from "../../styles/FeatherIcon"
import { I18nextContext } from "gatsby-plugin-react-i18next"

export const HeaderStyle = styled.header`
  position: sticky;
  top: 0;
  z-index: 1;
  transition: all 0.2s linear;
  padding: 1rem 1rem 0;
  background-color: var(--bg);
  display: flex;
  transition:
    color ${globalTransition},
    background-color ${globalTransition};
`

const BackLink = styled((props) => <Link {...props} />)`
  flex: none;
`

const IconPadding = styled.div`
  margin-top: 0.1875em;
  margin-bottom: 0.1875em;
  flex: none;
  margin-right: 1rem;
`
const Search = styled(SearchFieldComponent)`
  flex-grow: 1;
  min-width: 0;
`
const Icon = styled((props) => <ThemedSVG {...props} />)`
  padding-left: 0.3em;
  padding-right: 0.3em;
  height: 2em;
  width: 2em;
`

interface ToggleContainerProps {
  readonly $darkTheme: boolean
  children: any
  id: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

// based on: https://css-tricks.com/a-dark-mode-toggle-with-react-and-themeprovider/
const ToggleContainer = styled.button<ToggleContainerProps>`
  border: 2px solid var(--neutral20);
  background: var(--neutral0);
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  flex: none;
  font-size: 0.5rem;
  justify-content: space-between;
  margin-top: 0.2rem;
  margin-bottom: 0rem;
  margin-left: 1rem;
  margin-right: 0;
  overflow: hidden;
  padding: 0.3rem;
  position: relative;
  width: 4rem;
  height: 2rem;
  transition:
    background-color ${globalTransition},
    border-color ${globalTransition};

  svg {
    transition: all 0.2s linear;
    height: auto;
    width: 1.2rem;

    &:first-child {
      transform: ${({ $darkTheme }) =>
        $darkTheme ? "translateY(0)" : "translateY(100px)"};
    }

    &:nth-child(2) {
      transform: ${({ $darkTheme }) =>
        $darkTheme ? "translateY(-100px)" : "translateY(0)"};
    }
  }
`
const IconOrBack: React.FC<{ atIndex: boolean }> = ({ atIndex }) => {
  /* const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            iconPath
          }
        }
      }
    `
  )
  const { iconPath } = site.siteMetadata as SnapsenMeta */

  return (
    <IconPadding>
      {atIndex ? (
        <Icon src={iconPath} alt="Snapsen" id="snapsen-icon" />
      ) : (
        <BackLink id="back-button" to={`/`}>
          <ArrowLeft color={"var(--fg)"} size={"2em"} />
        </BackLink>
      )}
    </IconPadding>
  )
}

const DarkModeToggle: React.FC = () => (
  <ThemeToggler>
    {({ theme, toggleTheme }) => (
      <ToggleContainer
        id="dark-mode-toggle"
        onClick={() => toggleTheme(theme === "dark" ? "light" : "dark")}
        $darkTheme={theme === "dark"}
      >
        <Moon {...buttonProps} />
        <Sun {...buttonProps} />
      </ToggleContainer>
    )}
  </ThemeToggler>
)

const Header: React.FC<{ path: string }> = ({ path }) => {
  // Set isClient on first render
  const [isClient, setClient] = useState(false)
  const i18nextContext = useContext(I18nextContext)

  useEffect(() => {
    setClient(true)
  }, [])

  // Do not render Icon in SSR, leads to hydration error
  return (
    <HeaderStyle>
      <>
        {isClient ? (
          <IconOrBack
            atIndex={
              i18nextContext.originalPath
                ? i18nextContext.originalPath === "/"
                : true
            }
          />
        ) : null}
        <Search />
        {isClient ? <DarkModeToggle /> : null}
      </>
    </HeaderStyle>
  )
}
export default Header
